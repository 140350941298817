/**
 *  SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 *  See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ seo = {} }) => {
	const { site } = useStaticQuery(query);

	// Merge default and page-specific SEO values
	const fullSeo = { ...seo };

	const getMetaTags = () => {
		const tags = [];

		if (fullSeo.metaTitle) {
			tags.push(
				{
					property: "og:title",
					content: fullSeo.metaTitle,
				},
				{
					name: "twitter:title",
					content: fullSeo.metaTitle,
				}
			);
		}
		if (fullSeo.metaDescription) {
			tags.push(
				{
					name: "description",
					content: fullSeo.metaDescription,
				},
				{
					property: "og:description",
					content: fullSeo.metaDescription,
				},
				{
					name: "twitter:description",
					content: fullSeo.metaDescription,
				}
			);
		}
		if (fullSeo.shareImage) {
			const imageUrl = site.siteMetadata.imagePrefix + fullSeo.shareImage;
			tags.push(
				{
					name: "image",
					content: imageUrl,
				},
				{
					property: "og:image",
					content: imageUrl,
				},
				{
					name: "twitter:image",
					content: imageUrl,
				}
			);
		}
		if (fullSeo.type) {
			tags.push({
				property: "og:type",
				content: fullSeo.type,
			});
		}
		tags.push({ name: "twitter:card", content: "summary_large_image" });
		if (site.siteMetadata.author) {
			tags.push({
				name: "twitter:creator",
				content: site.siteMetadata.author,
			});
		}
		tags.push({
			name: "keywords",
			content: "dapit,Dapit,dapit lu, dapit luxembourg,dapit luxemburg,dapit webdevelopement,dapit webdesign,webdevelopment,webdesign,dapit "+fullSeo.metaTitle+","+fullSeo.metaTitle,
		});

		return tags;
	};

	const metaTags = getMetaTags();

	return (
		<Helmet
			title={fullSeo.metaTitle}
			meta={metaTags}
			htmlAttributes={{lang: 'en'}}
		/>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	type: PropTypes.string,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	type: 'website',
};

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
				author
			}
		}
	}
`;
