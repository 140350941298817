import React from "react"

import Layout from "../components/layout-maintenance"

const ErrorPage = () => {

    var  errorSeo = { metaTitle:'404: Not Found', metaDescription:'This page is not found and doesn&#39;t exist'};

    return (
    <Layout pageen="/error3" pagelb="/fehler3" seo={errorSeo}>
        <section id="banner">
            <div className="inner">
                <h2 className="mt-5">404: Not Found</h2>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </section>
    </Layout>
    );
}

export default ErrorPage
