import React from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from "gatsby";

import Seo from "./seo";

import '../assets/bootstrap-5.x/css/bootstrap.min.css';
import '../assets/fontawesome-5.x/css/all.min.css';
import '../assets/css/main.css';

import 'jquery';
import 'react-popper';

const Layout = ({ children, seo, pageen, pagelb }) => (
			<>
			<Helmet
			noscript={[{
				link: {
					rel: "stylesheet",
					href: "../assets/css/noscript.css",
				},
			}]} />

			<Seo seo={seo} />
			<div id="page-wrapper">
			hello
				{children}

			</div>
			</>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
